<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>
        <div v-if="is_API_loaded" class="space-y-10">
          <!-- 購物金功能開關 -->
          <section class="wrap">
            <div class="leftBlock">
              <!-- <p>※ 請先開啟此開關來啟用下列功能。</p> -->
              <div class="p-4 rounded-xl bg-white space-y-4">
                <h3 class="h3">使用條件摘要</h3>
                <div class="space-y-2">
                  <p class="list_item" v-if="setting.point1_discount_charge === 0">不限金額皆可折抵購物金。</p>
                  <p class="list_item" v-else>單筆最低消費需滿 NT${{ setting.point1_discount_charge | currency }}，才可折抵購物金。</p>
                  <p class="list_item" v-if="setting.point1_discount_type === 0">折抵購物金 無上限。</p>
                  <p class="list_item" v-else-if="setting.point1_discount_type === 1">
                    購物金折抵上限為 NT${{ setting.point1_discount_pays | currency }}。
                  </p>
                  <p class="list_item" v-else-if="setting.point1_discount_type === 2">
                    購物金折抵上限為消費金額的 {{ setting.point1_discount_pays }}%。
                  </p>
                </div>
              </div>
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">購物金功能開關</h3>
                <vs-switch warn v-model="setting.point1_discount" />
              </div>

              <div class="p-4">
                <h3 class="h3 text-center mb-4">購物金使用條件設定</h3>
                <div class="p-4 space-y-6 bg-gray-background rounded-lg">
                  <div>
                    <div class="flex items-center justify-between">
                      <h4 class="h4 mb-1">單筆最低消費需滿</h4>

                      <span class="text-gray-400">※無低消請輸入 0</span>
                    </div>
                    <div class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="單筆最低消費"
                        :rules="`${setting.point1_discount ? 'required' : ''}|numeric|min_value:0`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_discount_charge"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">購物金折抵上限類型</h4>
                    <div class="flex flex-col items-start justify-start space-y-2">
                      <vs-radio success v-model="setting.point1_discount_type" :val="0"> 無上限 </vs-radio>
                      <vs-radio success v-model="setting.point1_discount_type" :val="1"> 以固定金額 </vs-radio>
                      <vs-radio success v-model="setting.point1_discount_type" :val="2"> 以消費金額的百分比 </vs-radio>
                    </div>
                  </div>
                  <div v-if="setting.point1_discount_type > 0">
                    <h4 class="h4 mb-1">折抵金額上限為</h4>
                    <div v-if="setting.point1_discount_type === 1" class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="折抵金額上限"
                        :rules="`${setting.point1_discount ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_discount_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <div v-if="setting.point1_discount_type === 2" class="flex items-center space-x-2">
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="折抵上限金額"
                        :rules="`${setting.point1_discount ? 'required' : ''}|numeric|min_value:1|max_value:99`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_discount_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- 新會員購物金 -->
          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">新會員購物金</h3>
              <p>顧客註冊成為新會員時，系統會自動發送購物金。</p>
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">新會員購物金</h3>
                <vs-switch  success v-model="setting.point1_member" />
              </div>
              <transition name="fade">
                <div class="rightBlock_content">
                  <div>
                    <h4 class="h4 mb-1">發送金額</h4>
                    <div class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="發送金額"
                        :rules="`${setting.point1_member ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_member_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </section>
          <!-- 生日購物金 -->
          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">生日購物金</h3>
              <p>會員生日當天，系統會自動發送購物金。</p>
              <p>為防止會員隨意更改生日詐領購物金，系統每年只會發送給同位會員一次。</p>
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">生日購物金</h3>
                <vs-switch  success v-model="setting.point1_birthday" />
              </div>
              <transition name="fade">
                <div class="rightBlock_content">
                  <div>
                    <h4 class="h4 mb-1">發送金額</h4>
                    <div class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="發送金額"
                        :rules="`${setting.point1_birthday ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_birthday_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </section>
          <!-- 滿額贈購物金 -->
          <section class="wrap">
            <div class="leftBlock">
              <h3 class="h3 sm:mt-4">滿額贈購物金</h3>
              <p>會員單筆消費滿指定金額，系統會自動發送購物金。</p>
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <h3 class="h3">滿額贈購物金</h3>
                <vs-switch  success v-model="setting.point1_shopfull" />
              </div>
              <transition name="fade">
                <div class="rightBlock_content">
                  <div>
                    <h4 class="h4 mb-1">單筆最低消費(不含運費)需滿</h4>
                    <div class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="單筆最低消費"
                        :rules="`${setting.point1_shopfull ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_shopfull_charge"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">發送類型</h4>
                    <div class="flex items-center justify-start space-x-2">
                      <vs-radio success v-model="setting.point1_shopfull_type" :val="0"> 固定金額 </vs-radio>
                      <vs-radio success v-model="setting.point1_shopfull_type" :val="1"> 百分比 </vs-radio>
                    </div>
                  </div>
                  <div>
                    <h4 class="h4 mb-1">發送金額</h4>
                    <!-- 購物金類型為固定金額 -->
                    <div v-if="setting.point1_shopfull_type === 0" class="flex items-center space-x-2">
                      <span>NT$</span>
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="發送金額"
                        :rules="`${setting.point1_shopfull ? 'required' : ''}|numeric|min_value:1`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_shopfull_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                    </div>
                    <!-- 購物金類型為百分比 -->
                    <div v-if="setting.point1_shopfull_type === 1" class="flex items-center space-x-2">
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="發送金額"
                        :rules="`${setting.point1_shopfull ? 'required' : ''}|numeric|min_value:1|max_value:99`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_shopfull_pays"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>
                      <span>%</span>
                    </div>
                  </div>

                  <div>
                    <h4 class="h4 mb-1">已取貨/已送達 後多少天發送購物金</h4>
                    <div class="flex items-center space-x-2">
                      <ValidationProvider
                        tag="div"
                        class="w-full relative"
                        name="發送天數"
                        :rules="`${setting.point1_shopfull ? 'required' : ''}|numeric|min_value:1|max_value:30`"
                        v-slot="{ errors }"
                      >
                        <vs-input v-model="setting.point1_shopfull_send"></vs-input>
                        <errorDiv>{{ errors[0] }}</errorDiv>
                      </ValidationProvider>

                      <span>天</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </section>
          <hr />
          <div class="flex items-center justify-between">
            <router-link :to="{ name: 'giftVoucher-history' }">
              <vs-button size="large" border>查閱發送紀錄</vs-button>
            </router-link>

            <vs-button :disabled="invalid" size="large" ref="saveBtn" @click="save(invalid)">儲存</vs-button>
          </div>
        </div>

        <!-- 骨架屏 -->
        <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="rightBlock_title">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="rightBlock_content">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" height="10px" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'giftVoucher',
  data() {
    return {
      is_API_loaded: false,
      setting: {},
    }
  },
  created() {
    this.loadSetting()
  },
  watch: {
    // 若總開關關閉，全部關閉
    'setting.point1_discount'(val) {
      if (!val) {
        this.setting.point1_member = false
        this.setting.point1_birthday = false
        this.setting.point1_shopfull = false
      }
    },
    'setting.point1_member'(val) {
      if (val) {
        this.setting.point1_discount = true
      }
    },
    'setting.point1_birthday'(val) {
      if (val) {
        this.setting.point1_discount = true
      }
    },
    'setting.point1_shopfull'(val) {
      if (val) {
        this.setting.point1_discount = true
      }
    }, 
  },
  methods: {
    // 3.2.3.1 購物金參數設定-讀取
    loadSetting() {
      this.$axios({
        url: 'front/store/point1/getPoint1ParamUnit.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          function booleanChange(value) {
            return value ? true : false
          }

          let data = res.data.Data
          data.point1_discount = booleanChange(data.point1_discount)
          data.point1_member = booleanChange(data.point1_member)
          data.point1_birthday = booleanChange(data.point1_birthday)
          data.point1_shopfull = booleanChange(data.point1_shopfull)
          this.setting = data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.2.3.2 購物金參數設定-修改
    save(invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'success',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/point1/uPoint1ParamData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          point1_discount: this.setting.point1_discount ? 1 : 0,
          point1_discount_charge: this.setting.point1_discount_charge,
          point1_discount_type: this.setting.point1_discount_type,
          point1_discount_pays: this.setting.point1_discount_pays,
          point1_member: this.setting.point1_member ? 1 : 0,
          point1_member_pays: this.setting.point1_member_pays,
          point1_birthday: this.setting.point1_birthday ? 1 : 0,
          point1_birthday_pays: this.setting.point1_birthday_pays,
          point1_shopfull: this.setting.point1_shopfull ? 1 : 0,
          point1_shopfull_type: this.setting.point1_shopfull_type,
          point1_shopfull_charge: this.setting.point1_shopfull_charge,
          point1_shopfull_pays: this.setting.point1_shopfull_pays,
          point1_shopfull_send: this.setting.point1_shopfull_send,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.loadSetting()
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white rounded-xl;
}

.rightBlock_title {
  @apply flex items-center justify-between border-b p-4;
}

.rightBlock_content {
  @apply p-4 space-y-6;
}

.list_item {
  @apply ml-0 relative px-4;

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: var(--gray-border);
  }
}
</style>
